<template>
  <div id="questionnaire">
    <div class="title">填写问卷</div>
    <div class="form">
      <one-choice
        v-for="item in oneChoice"
        :key="item.id"
        :check-question="item"
      ></one-choice>
      <mutiple-choice
        v-for="item in multipleChoice"
        :key="item.id"
        :check-question="item"
      ></mutiple-choice>
    </div>
    <button
      class="btn"
      :style="{ backgroundColor: primaryColor }"
      @click="submitQuestionnaire"
    >
      下一步
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { getQuestionnaire } from "@/api/questionnaire";
import MutipleChoice from "@/views/form/questionnaire/components/MutipleChoice";
import OneChoice from "@/views/form/questionnaire/components/OneChoice";

import UserQuestionAnswerService from "../../../service/user-question-answers";
import UserVisitRecordService from "../../../service/user-visit-record";

export default {
  name: "Questionnaire",
  components: { MutipleChoice, OneChoice },
  computed: {
    ...mapState({
      primaryColor: (state) => state.application.properties.primaryColor,
      application: (state) => state.application,
      account: (state) => state.account,
      user: (state) => state.user,
      exhibition: (state) => state.exhibition,
      businessCard: (state) => state.businessCard,
      visitRecord: (state) => state.visitRecord,
      userQuestionnaireId: (state) =>
        state.application.properties.userQuestionnaireId,
      questionnaireId: (state) => state.application.properties.questionnaireId,
    }),
  },
  data() {
    return {
      dataForm: [],
      multipleChoice: [],
      oneChoice: [],
      levelChoice: [],
      answers: [], // 放答案的数组
      questionList: [],
    };
  },
  async created() {
    getQuestionnaire(
      this.$store.state.application.properties.questionnaireId
    ).then((res) => {
      if (res.status === 200 && res.data) {
        console.log(res.data);
        this.questionList = res.data;
        for (let [index, item] of res.data.entries()) {
          item.index1 = index;
          this.multipleChoice.push(item);
          /*if (item.type === 'ONE_CHOICE') {
            this.oneChoice.push(item);
          } else if (item.type === 'MULTIPLE_CHOICE') {
            this.multipleChoice.push(item);
          }*/
          let answer = { question: { id: item.id }, options: [] };
          this.answers.push(answer);
        }
      }
    });

    await this.$store.dispatch("getVisitRecord");
  },
  methods: {
    ...mapActions({
      getVisitRecord: "getVisitRecord",
    }),
    async submitQuestionnaire() {
      try {
        if (this.answerValidate()) {
          const result = await UserQuestionAnswerService.addUserQuestionAnswers(
            this.user,
            this.answers
          );

          this.$message({
            type: "success",
            message: "提交成功",
            duration: 2000,
          });

          console.log(result);

          const params = this.visitRecord;

          params.properties.applicationId = this.application.id;
          params.properties.questionnaires.push(this.questionnaireId);
          params.progress = "REGISTERED";

          await UserVisitRecordService.updateUserVisitRecord(this.user, params);

          this.$router.push("/index/success");
        } else {
          this.$message({
            type: "error",
            message: "您还有必做题未作答！",
            duration: 2000,
          });
        }
      } catch (error) {
        console.log(error);

        this.$message({
          type: "error",
          message: error.response ? error.response.data.message : error.message,
          duration: 2000,
        });
      }
    },
    // 判断是否必做题都作答了
    answerValidate() {
      let valid = true;
      for (let i = 0; i < this.questionList.length; i++) {
        if (
          this.questionList[i].required &&
          this.answers[i].options.length === 0
        ) {
          valid = false;
          break;
        }
      }
      return valid;
    },
  },
};
</script>

<style scoped lang="scss">
#questionnaire {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
    margin: 24px 0;
  }

  .btn {
    margin: 30px 0;
    width: 320px;
    height: 40px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
    line-height: 28px;
    letter-spacing: 10px;
    border: none;
    outline: none;
  }
}
</style>
