<template>
  <div id="check-question">
    <div class="title">
      <label class="required" style="color: #FF442F; width: 5px; margin-right: 5px" v-if="checkQuestion.required">*</label>
      <label class="required" style="color: #FF442F; width: 5px; margin-right: 5px" v-if="!checkQuestion.required"></label>
      {{checkQuestion.index1 + 1}}.({{checkQuestion.type === 'MULTIPLE_CHOICE' ? '多选': '单选'}}){{checkQuestion.content}}
    </div>
    <div class="options">

      <!-- 第一层选项 -->
      <div class="option" v-for="(item, index) in optionList" :key="index">
        <div class="first-option">
          <div  @click="showMessage" class="option-wrapper" v-if="questionType === 'MULTIPLE_CHOICE'">
            <div class="option-content">
              <label v-if="!item.subOptions" style="margin-right: 5px" :for="item.id">
                <i class="el-icon-check icon"
                   :style="{'backgroundColor': optionList[index].checked ? $store.state.application.properties.primaryColor : '#fff'}"></i>
              </label>
              <input v-if="!item.subOptions" :disabled="optionDisabled && !optionList[index].checked" type="checkbox"
                     v-model="dataList" :value="item" :id="item.id" @change="changeCheck(index)">
              <label :for="item.id">{{item.content}}</label>
            </div>
            <input type="text" v-if="item.customized" v-model="optionList[index].value"
                   :disabled="!optionList[index].checked">
          </div>
          <div class="option-wrapper" v-if="questionType === 'ONE_CHOICE'">
            <div class="option-content">
              <label v-if="!item.subOptions" style="margin-right: 5px" :for="item.id"><i class="el-icon-check icon"
                                                                                         :style="{'backgroundColor': dataList.id === item.id ? $store.state.application.properties.primaryColor : '#fff'}"></i></label>

              <input v-if="!item.subOptions" type="radio" name="answer" v-model="dataList" :value="item" :id="item.id">
              <label :for="item.id">{{item.content}}</label>
            </div>
            <input type="text" v-if="item.customized" v-model="optionList[index].value"
                   :disabled="!(dataList.id === item.id)">
          </div>

        </div>


        <!--  第二层选项  -->
        <div class="option second-option" v-for="(subItem, subIndex) in item.subOptions" :key="subIndex">
          <div  @click="showMessage" class="second-option-wrapper" v-if="questionType === 'MULTIPLE_CHOICE'">
            <div class="option-content">
              <label style="margin-right: 5px" :for="subItem.id">
                <i class="el-icon-check icon"
                   :style="{'backgroundColor': optionList[index].subOptions[subIndex].checked ? $store.state.application.properties.primaryColor : '#fff'}"></i>
              </label>
              <input type="checkbox" :disabled="optionDisabled && !optionList[index].subOptions[subIndex].checked"
                     v-model="dataList" :value="subItem" :id="subItem.id" @change="changeSubCheck(index, subIndex)">
              <label :for="subItem.id">{{subItem.content}}</label>
            </div>
            <input type="text" v-if="subItem.customized" v-model="optionList[index].subOptions[subIndex].value"
                   :disabled="!optionList[index].subOptions[subIndex].checked">
          </div>
          <div  class="second-option-wrapper" v-if="questionType === 'ONE_CHOICE'">
            <div class="option-content">
              <label style="margin-right: 5px" :for="subItem.id"><i class="el-icon-check icon"
                                                                    :style="{'backgroundColor': dataList.id === subItem.id ? $store.state.application.properties.primaryColor : '#fff'}"></i></label>

              <input type="radio" name="answer" v-model="dataList" :value="subItem" :id="subItem.id">
              <label :for="subItem.id">{{subItem.content}}</label>
            </div>
            <input type="text" v-if="subItem.customized" v-model="optionList[index].subOptions[subIndex].value"
                   :disabled="!(dataList.id === subItem.id)">
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import '@/assets/css/myCSS.css'

export default {
  name: "MutipleChoice",
  props: {
    checkQuestion: {
      type: Object
    }
  },
  data() {
    return {
      dataList: [], // 选中的数据
      optionList: [], // 所有的选项
      customizedContent: '',
      questionIndex: null,
      optionDisabled: false,
      maximun: '',
      questionType: ''
    }
  },
  computed: {
    optionCount() {
      return this.dataList.length;
    }
  },
  watch: {
    dataList: {
      handler: function (newVal) {
        let answerList = [];
        if (this.questionType === 'MULTIPLE_CHOICE' && newVal.length !== 0) {
            for (let item of newVal) {
              answerList.push(this.formatAnswer(item));
            }
        } else if (this.questionType === 'ONE_CHOICE' && newVal){
          answerList.push(this.formatAnswer(newVal))
        }
        this.$parent.answers[this.questionIndex].options = answerList
      },
      deep: true
    },
    optionCount: {
      handler: function (newVal) {
        if (this.questionType === 'MULTIPLE_CHOICE' && newVal === this.maximun) {
          this.optionDisabled = !this.optionDisabled
        } else {
          this.optionDisabled = false
        }
      },
      deep: true
    }
  },
  created() {
    if (this.checkQuestion) {
      this.questionType = this.checkQuestion.type;
      this.maximun = this.checkQuestion.maximum || this.checkQuestion.options.length + 1;
      this.questionIndex = this.checkQuestion.index1;
      let optionList = this.checkQuestion.options;
      this.optionList = optionList.map(item => {
        let obj = {};
        obj.content = item.content;
        obj.id = item.id;
        obj.value = ''
        obj.checked = false;
        obj.customized = item.customized;
        if (item.subordinates.length > 0) {
          obj.subOptions = item.subordinates.map(item => {
            let obj = {};
            obj.content = item.content;
            obj.id = item.id;
            obj.value = ''
            obj.checked = false;
            obj.customized = item.customized;
            return obj;
          })
        } else {
          obj.subOptions = null
        }
        return obj;
      })
    }
  },
  methods: {
    formatAnswer(item) {
      let answer
      if (item.customized) {
        answer = {value: item.value, option: {id: item.id}}
      } else {
        answer = {option: {id: item.id}}
      }
      return answer
    },
    changeCheck(index) {
      this.optionList[index].checked = !this.optionList[index].checked;
    },
    changeSubCheck(index, subIndex) {
      this.optionList[index].subOptions[subIndex].checked = !this.optionList[index].subOptions[subIndex].checked
    },
    showMessage() {
      if (this.optionDisabled) {
        this.$message({type: 'error', message: `最多选择${this.maximun}项`})
      }
    }
  }
}
</script>

<style scoped lang="scss">
#check-question {
  width: 100%;
  margin-bottom: 25px;

  label {
    display: inline-block;
  }

  .title {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #646464;

    .required {
      margin-right: 5px;
    }
  }

  .options {
    margin-top: 10px;
    padding-left: 30px;

    .option {
      margin-bottom: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 14px;
      .option-wrapper {
        display: flex;
        align-items: center;
      }
      .first-option {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }
      .second-option-wrapper {

      }
      .second-option {
        margin-left: 20px;
        display: flex;
        align-items: center;
      }

      .option-content {
        margin-right: 5px;

        .icon {
          width: 15px;
          height: 15px;
          border: 1px solid #DDDDDD;
          color: #fff;
        }
      }

      input[type='text'] {
        height: 32px;
        padding: 8px;
        box-sizing: border-box;
        border: 1px solid #DDDDDD;
      }
    }
  }
}

</style>
