import axios from "@/utils/request";

export function getQuestionnaire(questionnaireId) {
  return axios({
    url: `/questionnaire/${questionnaireId}/question`,
    method: 'get'
  })
}

export function submitQuestionnaire(userId, answers) {
  return axios({
    url: `/user/${userId}/question-answers`,
    method: 'post',
    data: answers
  })
}

// 创建参观记录

export function createVisitRecord(userId, exhibitionId, properties) {
  return axios({
    url: '/visit-record',
    methods: 'post',
    data: {properites: properties, user: {id: userId}, exhibition: {id: exhibitionId}}
  })
}
