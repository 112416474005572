<template>
  <div id="oneChoice">
    <div class="title">
      <label class="required" style="color: #FF442F; width: 5px" v-if="checkQuestion.required">*</label>
      <label class="required" style="color: #FF442F; width: 5px" v-if="!checkQuestion.required"></label>
      {{checkQuestion.content}}(单选)
    </div>
    <div class="options">
      <!-- 第一层选项 -->
      <div class="option" v-for="(item, index) in optionList" :key="index">
        <div class="firstOption">
          <div class="option-content">
            <label v-if="!item.subOptions" style="margin-right: 5px" :for="item.id"><i class="el-icon-check icon"
                                                                                       :style="{'backgroundColor': dataList.id === item.id ? $store.state.application.properties.primaryColor : '#fff'}"></i></label>

            <input v-if="!item.subOptions" type="radio" name="answer" v-model="dataList" :value="item" :id="item.id">
            <label :for="item.id">{{item.content}}</label>
          </div>
          <input type="text" v-if="item.customized" v-model="optionList[index].value"
                 :disabled="!(dataList.id === item.id)">
        </div>


        <!--  第二层选项  -->
        <div class="option second-option" v-for="(subItem, i) in item.subOptions" :key="i">
          <div class="option-content">
            <label style="margin-right: 5px" :for="subItem.id"><i class="el-icon-check icon"
                                                                  :style="{'backgroundColor': dataList.id === subItem.id ? $store.state.application.properties.primaryColor : '#fff'}"></i></label>

            <input type="radio" name="answer" v-model="dataList" :value="subItem" :id="subItem.id">
            <label :for="subItem.id">{{subItem.content}}</label>
          </div>
          <input type="text" v-if="subItem.customized" v-model="optionList[index].subOptions[i].value"
                 :disabled="!(dataList.id === subItem.id)">
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OneChoice",
  data() {
    return {
      dataList: {}, // 选中的数据
      optionList: [], // 所有的选项
      questionIndex: null
    }
  },
  props: {
    checkQuestion: {
      type: Object
    }
  },
  watch: {
    dataList: {
      handler: function (newVal) {
        console.log(newVal)
        let answerList = [];
        if (newVal) {
          answerList.push(this.formatAnswer(newVal))
        }
        this.$parent.answers[this.questionIndex].options = answerList
      },
      deep: true
    }
  },
  created() {
    if (this.checkQuestion) {
      this.questionIndex = this.checkQuestion.index1;
      let optionList = this.checkQuestion.options;
      this.optionList = optionList.map(item => {
        let obj = {};
        obj.content = item.content;
        obj.id = item.id;
        obj.value = ''
        obj.customized = item.customized;
        if (item.subordinates.length > 0) {
          obj.subOptions = item.subordinates.map(item => {
            let obj = {};
            obj.content = item.content;
            obj.id = item.id;
            obj.value = ''
            obj.customized = item.customized;
            return obj
          })
        } else {
          obj.subOptions = null
        }
        return obj;
      })
    }
  },
  methods: {
    formatAnswer(item) {
      let answer
      if (item.customized) {
        answer = {value: item.value, option: {id: item.id}}
      } else {
        answer = {option: {id: item.id}}
      }
      return answer
    },
  }
}
</script>

<style scoped lang="scss">
#oneChoice {
  width: 100%;
  margin-bottom: 25px;

  label {
    display: inline-block;
  }

  .title {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #646464;

    .required {
      margin-right: 5px;
    }
  }

  .options {
    margin-top: 10px;
    padding-left: 30px;

    .option {
      margin-bottom: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 14px;

      .firstOption {
        display: flex;
        align-items: center;
      }

      .second-option {
        margin-left: 20px;
        display: flex;
        align-items: center;
      }

      .option-content {
        margin-right: 5px;

        .icon {
          width: 15px;
          height: 15px;
          border: 1px solid #DDDDDD;
          color: #fff;
        }
      }

      input[type='text'] {
        height: 32px;
        padding: 8px;
        box-sizing: border-box;
        border: 1px solid #DDDDDD;
      }
    }
  }
}
</style>
